<template>
  <ElDialog
    :visible="visible"
    :before-close="onBeforeClose"
    top="70px"
    width="450px"
  >
    <h2>Move this product type</h2>
    <p>
      You are moving <strong>{{ productType.name }}</strong>.
      Once completed, you will not be able to undo this move.
    </p>
    <ElForm>
      <label for="carrier-select">
        Select a carrier to move this product to:
      </label>
      <ElSelect
        id="carrier-select"
        v-model="selectedCarrierId"
        :disabled="isSaving"
        data-test="select carrier for moving product type"
        placeholder="Select new carrier"
      >
        <template v-for="{ id, name } of availableCarriers">
          <ElOption
            v-if="id !== +currentCarrierId"
            :key="id"
            :data-test="`select carrier ${id}`"
            :label="name"
            :value="id"
          />
        </template>
      </ElSelect>
    </ElForm>
    <AppButton
      :is-disabled="!selectedCarrierId"
      :is-loading="isSaving"
      data-test="confirm product type move"
      size="large"
      icon="fa-solid fa-check"
      text="Move product type"
      @click="moveProductType"
    />
  </ElDialog>
</template>

<script>
  import { useManagementCarriersStore } from '@/stores/CarrierManagement/carriers.js';
  import ManagementCarrierService from '@/services/CarrierManagement/carrier.js';
  import { mapState } from 'pinia';

  export default {
    name: 'MoveProductTypeModal',
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      /**
       * just the name and id of the product type
       */
      productType: {
        type: Object,
        default: () => ({}),
      },
    },
    data: () => ({
      selectedCarrierId: null,
      isSaving: false,
    }),
    computed: {
      ...mapState(useManagementCarriersStore, ['availableCarriers', 'currentCarrierId']),
    },
    methods: {
      /**
       * moves the product type
       */
      async moveProductType() {
        this.isSaving = true;

        try {
          await ManagementCarrierService.moveProductType(
            this.currentCarrierId,
            this.selectedCarrierId,
            this.productType.id,
          );
          this.$emit('removeProductType', +this.productType.id);
          const { name } = this.availableCarriers.find(({ id }) => +id === +this.selectedCarrierId);

          this.$message({
            showClose: true,
            message: `You have successfully moved ${this.productType.name} to ${name}.`,
            type: 'success',
          });
        } catch {
          this.$message({
            showClose: true,
            message: 'There was an error moving the product type. Please try again',
            type: 'error',
          });
        } finally {
          this.$emit('update:visible', false);
          this.$emit('close', false);
          this.selectedCarrierId = null;
        }
      },
      /**
       * before the modal closes we need to update the visibility
       * then call the done() function
       *
       * @param {Function} done
       */
      onBeforeClose(done) {
        this.$emit('update:visible', false);
        this.$emit('close', false);
        this.selectedCarrierId = null;
        done();
      },
    },
  };
</script>

<style lang="scss" scoped>
form {
  margin-bottom: 20px;
}

:deep() {
  .el-dialog {
    &__header {
      border-bottom: none;
    }

    &__body {
      padding: 0 60px 60px;
      text-align: center;
    }

    .tf-button {
      margin-top: 50px;
    }
  }

  .el-select {
    width: 100%;
  }
}

label {
  display: block;
  width: 100%;
  font-weight: 700;
  text-align: left;
  margin: 15px 0 10px;
}
</style>
