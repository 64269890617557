<template>
  <div
    v-if="currentCarrier"
    class="carrier-content management-content"
  >
    <ManagementHeader
      :breadcrumb="{ name: 'CarrierManagement' }"
      breadcrumb-text="Back to carriers"
      data-test="carrier header"
    >
      Carrier info &ndash; {{ currentCarrier.name }}
    </ManagementHeader>

    <TfTabs
      :value="tabValue"
      @tab-click="onTabClick"
    >
      <ElTabPane
        v-for="({ label, name }) in panes"
        :key="label"
        :name="name"
      >
        <template slot="label">
          <span :data-test="`to ${name}`">
            {{ label }}
          </span>
        </template>
      </ElTabPane>
    </TfTabs>
    <RouterView />
  </div>
</template>

<script>
// pinia
  import { useManagementCarriersStore } from '@/stores/CarrierManagement/carriers.js';
  import { mapActions, mapState, mapWritableState } from 'pinia';
  // components
  import ManagementHeader from '@/components/Management/Header.vue';
  import ManagementCarrierService from '@/services/CarrierManagement/carrier.js';

  /**
   * "Edit existing Carrier" view
   *
   * @exports EditCarrier
   */
  export default {
    name: 'EditCarrier',
    components: {
      ManagementHeader,
    },
    props: {
      carrierId: {
        type: [Number, String],
        default: null,
      },
    },
    data() {
      return {
        panes: [
          {
            label: 'Users',
            name: 'EditCarrierUsers',
          },
          {
            label: 'Product types',
            name: 'EditCarrierProductTypes',
          },
          {
            label: 'Email domains',
            name: 'EditCarrierDomains',
          },
          {
            label: 'Settings',
            name: 'EditCarrierSettings',
          },
        ],
      };
    },
    computed: {
      ...mapState(useManagementCarriersStore, ['carriers', 'currentCarrier']),
      ...mapWritableState(useManagementCarriersStore, ['currentCarrierId']),
      /**
       * Name
       *
       * @returns {string}
       */
      tabValue() {
        return this.$route.name;
      },
    },
    /**
     * Sets the currentCarrier to the passed carrierId
     */
    created() {
      this.currentCarrierId = this.carrierId;
      this.setCurrentCarrier();

      if (!this.carriers.length) {
        ManagementCarrierService.getCarriers()
          .then(({ carriers }) => {
            this.setCarriers(carriers);
            this.setCurrentCarrier();
          });
      }
    },
    beforeDestroy() {
      this.resetCurrentCarrier();
    },
    methods: {
      ...mapActions(useManagementCarriersStore, ['setCarriers', 'setCurrentCarrier', 'resetCurrentCarrier']),
      /**
       * Evaluates the name to determine the correct route params to push.
       *
       * @param {object} root
       * @param {string} root.name
       */
      onTabClick({ name }) {
        this.$router.push({ name });
      },
    },
  };
</script>

<style lang="scss">
  .carrier-content .el-tabs__content {
    margin-top: 40px;
  }
</style>
