<template>
  <div
    class="page"
    :style="pageStyle"
  >
    <div
      class="image"
      :style="containerStyle"
    >
      <img
        v-if="isActive"
        :src="page.source_url"
        :style="pageSize"
        alt=""
      >
    </div>
    <div
      v-if="isActive"
      :style="containerStyle"
      class="words"
    >
      <div
        v-for="word of page.words"
        :key="word.id"
        :data-word-id="word.id"
        :data-test="word.text.toLowerCase()"
        :style="{ ...wordStyles(word) }"
        :class="getWordClass(word.id)"
        @mouseover="handleWordMouseOver(word.id)"
        @click="handleWordClick($event, word.id)"
      />
      <AnnotationMarkerRow
        v-for="(annotationMarkers, index) of annotationMarkerRows"
        :key="`${page.id}-annotation-marker-row-${index}`"
        :annotation-markers="annotationMarkers"
        :page-id="page.id"
        :style="pageSize"
      />
    </div>
  </div>
</template>

<script>
  import { mapState } from 'pinia';
  import { usePdfViewerStore } from '@/stores/pdfViewer.js';

  import AnnotationMarkerRow from '@/components/FileViewer/PdfViewer/AnnotationMarkerRow.vue';

  /**
   * PDF rendering for FileViewer
   *
   * @vuedoc
   * @exports PdfViewerPage
   * @category Components
   */
  export default {
    name: 'PdfViewerPage',
    components: {
      AnnotationMarkerRow,
    },
    inject: ['pdfViewer'],
    props: {
      annotations: {
        type: Array,
        default: () => [],
      },
      isActive: {
        type: Boolean,
        default: false,
      },
      page: {
        type: Object,
        default: () => ({}),
      },
    },
    computed: {
      ...mapState(usePdfViewerStore, [
        'getScale',
        'getWordClass',
      ]),
      /**
       * Style for the page element, potentially rotated
       *
       * @returns {string}
       */
      pageStyle() {
        let width = this.page.width * this.getScale;
        let height = this.page.height * this.getScale;

        if (this.page.pageRotation === 90 || this.page.pageRotation === 270) {
          width = this.page.height * this.getScale;
          height = this.page.width * this.getScale;
        }

        return `width: ${width}px; height: ${height}px;`;
      },
      /**
       * Image and annotation markers do not need to be transformed/rotated but they do need an explicit size
       *
       * @returns {string}
       */
      pageSize() {
        return `width: ${this.page.width * this.getScale}px; height: ${this.page.height * this.getScale}px;`;
      },
      /**
       * Rotate and translate the image container and the words container
       *
       * @returns {string}
       */
      containerStyle() {
        let translate = '';

        if (this.page.pageRotation === 90) {
          translate = ' translateY(-100%);';
        } else if (this.page.pageRotation === 270) {
          translate = ' translateX(-100%);';
        }

        return `${this.pageSize} transform: rotate(${this.page.pageRotation}deg)${translate}`;
      },
      /**
       * Get all annotations markers for the active source, sorted by page, split by vertical position.
       *
       * @returns {Array}
       */
      annotationMarkerRows() {
        const annotationMarkerRows = {};

        this.annotations.forEach((annotation) => {
          const markerDetails = {
            annotation,
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            transform: this.page.pageRotation,
          };
          // What words on this page are part of this annotation?
          const wordIdsOnPage = annotation.word_ids.filter((wordId) => wordId.includes(`${this.page.id}-`));
          // Put the marker on the line of the first word of the annotation.
          const firstWord = this.page.words.find((word) => word.id === wordIdsOnPage[0]);

          // We can't find the word…this is an invalid annotation that we're trying to find the root cause for
          // Believe there's a race condition here which is causing the intermittent error
          // TODO: check for the race condition
          if (!firstWord) {
            this.pdfViewer.logSentryError({
              text: 'annotation describes word that does not exist (annotationMarkerRows)',
              payload: {
                pageId: this.page.id,
                annotation,
                wordIdsOnPage,
              },
            });

            return;
          }

          /**
           * Figure out the positioning for the marker
           * `verticalIndex` is used to group annotations on the same line
           */
          let verticalIndex = Math.round(firstWord.top / 10) * 10;

          if (this.page.pageRotation === 90) {
            verticalIndex = Math.round(firstWord.left / 10) * 10;
          } else if (this.page.pageRotation === 270) {
            verticalIndex = Math.round((this.page.width - firstWord.left - 12) / 10) * 10;
          }

          if (!annotationMarkerRows[verticalIndex]) {
            annotationMarkerRows[verticalIndex] = [];
          }

          switch (this.page.pageRotation) {
          case 90:
            markerDetails.left = verticalIndex;
            markerDetails.bottom = Math.round(12 * annotationMarkerRows[verticalIndex].length) - 1;
            break;
          case 180:
            markerDetails.top = verticalIndex;
            markerDetails.right = Math.round(12 * annotationMarkerRows[verticalIndex].length) - 1;
            break;
          case 270:
            markerDetails.right = verticalIndex;
            markerDetails.top = Math.round(12 * annotationMarkerRows[verticalIndex].length) - 1;
            break;
          default:
            markerDetails.top = verticalIndex;
            markerDetails.left = Math.round(12 * annotationMarkerRows[verticalIndex].length) - 1;
            break;
          }

          annotationMarkerRows[verticalIndex].push(markerDetails);
        });

        return annotationMarkerRows;
      },
    },
    methods: {
      /**
       * Word position
       *
       * @param {object} positionable
       * @returns {object}
       */
      wordStyles(positionable) {
        return {
          left: `${Math.round(positionable.left * this.getScale)}px`,
          top: `${Math.round(positionable.top * this.getScale)}px`,
          width: `${Math.round(positionable.width * this.getScale)}px`,
          height: `${Math.round(positionable.height * this.getScale)}px`,
        };
      },
      /**
       * Handle all interactions when the user hovers a word
       *
       * @param {number} wordId - Word Object being moused over
       */
      handleWordMouseOver(wordId) {
        if (
          this.pdfViewer.isAnnotating
          && this.pdfViewer.annotationType === 'click'
        ) {
          this.pdfViewer.setHoverWordIds(wordId);
        }
      },
      /**
       * Toggle "word selection mode" when a word is clicked
       *
       * @param {event} $event - Click event
       * @param {number} wordId - Word being clicked
       */
      handleWordClick($event, wordId) {
        if (this.pdfViewer.annotationMode !== 'draw') {
          if (this.pdfViewer.isAnnotating) {
            this.pdfViewer.finishWordClickAnnotation({ $event, wordId });
          } else {
            this.pdfViewer.startWordClickAnnotation(wordId);
          }
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .page {
    position: relative;
    border: 1px var(--tf-gray-medium) solid;
  }

  .image,
  .words {
    position: absolute;
    top: 0;
    left: 0;
    background-size: contain;
    transform-origin: 0 0;
  }

  .word {
    position: absolute;
    mix-blend-mode: multiply;
    cursor: pointer;
    background: rgba(0, 0, 0, .05);

    &:hover {
      background: rgba(0, 185, 128, .5);
    }
  }

  .inactive-highlight {
    background: rgba(255, 234, 0, .5);

    &:hover {
      background: var(--tf-yellow);
    }

    &.automated {
      background: rgba(#80c7fb, .5);

      &:hover {
        background: #80c7fb;
      }
    }
  }

  .temporary-highlight {
    background: rgba(0, 185, 128, .5);

    &:hover {
      background: var(--tf-green);
    }
  }

  .hover-highlight {
    background: rgba(0, 185, 128, .25);
  }

  .active-highlight,
  .active-highlight.automated {
    background: rgba(0, 185, 128, .5);

    &:hover {
      background: var(--tf-green);
    }
  }

  .active-search {
    .inactive-highlight {
      background: rgba(255, 234, 0, .25);
    }

    .word:hover {
      background: rgba(255, 234, 0, .25);
    }
  }

  .search-highlight {
    background: rgba(#911cf8, .15);

    &.search-highlight-active {
      background: rgba(#911cf8, .4);
    }

    &:hover {
      background: #911cf8;
    }
  }

  .annotation-marker {
    position: absolute;
    background: var(--tf-yellow);
    cursor: pointer;

    &.automated {
      background: #80c7fb;
    }

    &:hover {
      background: var(--tf-green);
    }
  }
</style>
