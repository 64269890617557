<template>
  <div>
    <ElForm
      ref="carrierManagementForm"
      :model="localCarrier"
      :rules="rules"
      label-position="top"
      @submit.native.prevent
    >
      <div
        class="carrier-info-block"
      >
        <div class="carrier-info-form">
          <div class="carrier-info">
            <!-- Carrier name -->
            <ElFormItem
              for="carrier-name"
              prop="name"
            >
              <label>Carrier name</label>
              <ElInput
                v-model.trim="localCarrier.name"
                :disabled="formSubmitting"
                data-test="edit carrier name"
                name="name-input"
                placeholder="Enter name"
              />
            </ElFormItem>
            <!-- AMB# -->
            <ElFormItem
              for="amb-number"
              prop="amb_number"
            >
              <label>AMB #</label>
              <ElInput
                v-model.trim="localCarrier.amb_number"
                :disabled="formSubmitting"
                data-test="edit carrier amb number"
                name="amb-number-input"
                placeholder="Enter AMB #"
              />
            </ElFormItem>
            <!-- AM Best Rating -->
            <ElFormItem>
              <label>AM Best rating</label>
              <ElSelect
                v-model="localCarrier.am_best_rating"
                :disabled="formSubmitting"
                data-test="edit am best rating"
                placeholder="Select a rating"
                @change="onChangeAmBestRating"
              >
                <ElOption
                  v-for="rating in amBestRatings"
                  :key="rating"
                  :value="rating"
                  :label="rating"
                  :data-test="`select ${rating} am best rating`"
                />
              </ElSelect>
            </ElFormItem>
            <ElFormItem
              for="am-best-rating-date"
              prop="am_best_rating_date"
            >
              <label>AM best date or validation date</label>
              <div class="datepicker-calendar">
                <TfDatepicker
                  v-model="localCarrier.am_best_rating_date"
                  format="MM/dd/yyyy"
                  :placeholder="disabledDatePicker ? '' : 'Please enter a date'"
                  :disabled="disabledDatePicker || formSubmitting"
                  :clear-button="true"
                  :use-utc="true"
                  :inline="false"
                  data-test="edit am best date"
                />
              </div>
            </ElFormItem>
          </div>
          <div>
            <!-- Enabled Switch -->
            <ElFormItem>
              <label>Enabled</label>
              <ElSwitch
                v-model="localCarrier.enabled"
                :disabled="formSubmitting"
                data-test="edit carrier is enabled"
              />
            </ElFormItem>
          </div>
        </div>
        <div class="btn-group align-start save-carrier-info">
          <AppButton
            :is-loading="formSubmitting"
            icon="fa-solid fa-check"
            data-test="save settings"
            text="Save settings"
            @click="saveCarrierInfo"
          />
        </div>
      </div>
    </ElForm>
    <div
      v-if="carrierId"
      class="uploads"
    >
      <LogoUpload
        name="Large logo"
        :action="`${apiUrl}/v1/carrier_portal/admin/carriers/${carrierId}`"
        :logo-url="localCarrier.logo_url"
        data-test="edit large logo"
        @updateLogo="updateLogo"
      />

      <LogoUpload
        name="Small logo"
        :action="`${apiUrl}/v1/carrier_portal/admin/carriers/${carrierId}`"
        :logo-url="localCarrier.small_logo_url"
        data-test="edit small logo"
        @updateLogo="updateLogo"
      />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapState, mapWritableState } from 'pinia';
  import { useManagementCarriersStore } from '@/stores/CarrierManagement/carriers.js';
  import { cloneDeep } from 'lodash';
  import LogoUpload from '@/components/Management/LogoUpload.vue';
  import { config } from '@/utils/config.js';
  import { amBestRatings } from '@/utils/management.js';
  import ManagementCarrierService from '@/services/CarrierManagement/carrier.js';

  /**
   * A view that allows a user to edit a carrier's various settings and logos.
   *
   * @exports CarrierSettings
   */
  export default {
    name: 'CarrierSettings',
    components: {
      LogoUpload,
    },
    props: {
      /**
       * The ID of the carrier that's currently being edited.
       */
      carrierId: {
        type: [Number, String],
        required: true,
      },
    },
    data() {
      return {
        apiUrl: config.VUE_APP_API_URL,
        localCarrier: {},
        formSubmitting: false,
        rules: {
          amb_number: [
            {
              validator: this.validateAmbNumber,
              trigger: 'blur',
            },
          ],
          name: [
            {
              required: true,
              type: 'string',
              message: 'Please enter the carriers name.',
              trigger: 'blur',
            },
          ],
          am_best_rating_date: [
            {
              type: 'string',
              trigger: 'blur',
              validator: this.validateAmBestRatingDate,
            },
          ],
        },
        amBestRatings,
      };
    },
    computed: {
      ...mapState(useManagementCarriersStore, ['currentCarrier']),
      ...mapWritableState(useManagementCarriersStore, ['currentCarrierId', 'carriers']),
      /**
       * The date picker is disabled if the rating is not specified ('--' a.k.a. the default blank value for AM Best Ratings).
       *
       * @returns {boolean}
       */
      disabledDatePicker() {
        return this.localCarrier.am_best_rating === '--';
      },
    },
    /**
     * Sets the currentCarrierId to the carrierId and sets it as the 'localCarrier' otherwise creates a blank newCarrier object.
     */
    created() {
      this.currentCarrierId = this.carrierId;

      if (this.currentCarrier) {
        this.$set(this, 'localCarrier', {
          ...cloneDeep(this.currentCarrier),
          enabled: !this.currentCarrier.disabled,
        });
      } else {
        const newCarrier = {
          am_best_rating: '--',
          am_best_rating_date: null,
          name: '',
          enabled: false,
          amb_number: '',
        };

        this.$set(this, 'localCarrier', newCarrier);
      }
    },
    methods: {
      ...mapActions(useManagementCarriersStore, ['updateCarrier']),
      /**
       * Null out the date if the AM best rating is set to '--'
       *
       * @param {string} value
       */
      onChangeAmBestRating(value) {
        if (value === '--') {
          this.localCarrier.am_best_rating_date = null;
        }
      },
      /**
       * Upload the logo passed up from the logoUpload component
       *
       * @param {object} payload
       * @param {string} payload.file
       * @param {string} payload.name
       */
      updateLogo({ file, name }) {
        const logoField = name === 'Small logo' ? 'small_logo' : 'logo';
        const formData = new FormData();

        formData.append(logoField, file);

        ManagementCarrierService
          .saveCarrierInfo(this.localCarrier.id, formData)
          .then(({ carrier }) => {
            this.updateCarrierFromResponse(carrier);
            this.showToast(`The carrier ${name.toLowerCase()} has been updated.`, 'success');
          })
          .catch(() => {
            this.showToast('There was an issue updating the logo.');
          });
      },
      /**
       * Update the store and local clone from the API response for Optimal Freshess(tm)
       *
       * @param {object} carrier
       */
      updateCarrierFromResponse(carrier) {
        const updatedCarrier = {
          ...carrier,
          enabled: !carrier.disabled,
        };

        this.$set(this, 'localCarrier', cloneDeep(updatedCarrier));
        this.updateCarrier(updatedCarrier);
      },
      /**
       * Save the carrier info (minus logos)
       */
      saveCarrierInfo() {
        if (this.formSubmitting) {
          return;
        }

        this.$refs.carrierManagementForm.validate((valid) => {
          if (valid) {
            this.formSubmitting = true;
            const amBestDate = this.localCarrier.am_best_rating_date
              ? new Date(this.localCarrier.am_best_rating_date)
              : null;
            let formattedAmBestDate = null;

            if (amBestDate) {
              // I need to format the date as 'MM/DD/YYYY' and first we set it to the users timezone
              amBestDate.setTime(amBestDate.getTime() + amBestDate.getTimezoneOffset() * 60 * 1000);
              formattedAmBestDate = `${amBestDate.getFullYear()}-${amBestDate.getMonth() + 1}-${amBestDate.getDate()}`;
            }

            const data = {
              am_best_rating: this.localCarrier.am_best_rating,
              am_best_rating_date: amBestDate ? formattedAmBestDate : null,
              disabled: !this.localCarrier.enabled,
              name: this.localCarrier.name,
              amb_number: this.localCarrier.amb_number,
            };

            if (!this.localCarrier.id) {
              ManagementCarrierService
                .addNewCarrier(data)
                .then(({ carrier }) => {
                  const newCarrier = {
                    ...carrier,
                    enabled: !carrier.disabled,
                  };

                  this.formSubmitting = false;

                  this.$set(this, 'localCarrier', cloneDeep(newCarrier));
                  this.carriers.push(newCarrier);

                  this.showToast('Carrier record has been created.', 'success');

                  this.$router.replace({
                    name: 'EditCarrierSettings',
                    params: { carrierId: carrier.id },
                  });
                })
                .catch(() => {
                  this.formSubmitting = false;
                  this.showToast('There was an error when trying to create the carrier.');
                });
            } else {
              // if this is an existing broker
              ManagementCarrierService
                .saveCarrierInfo(this.localCarrier.id, data)
                .then(({ carrier }) => {
                  this.formSubmitting = false;
                  this.updateCarrierFromResponse(carrier);
                  this.showToast('The carrier settings have been updated.', 'success');
                })
                .catch(({ response }) => {
                  this.formSubmitting = false;
                  this.showToast(response?.data?.message || 'Could not update carrier settings.');
                });
            }
          }
        });
      },
      /**
       * Show a "toast" error
       *
       * @param {string} message
       * @param {string} type
       */
      showToast(message, type = 'error') {
        this.$message({
          showClose: true,
          message,
          type,
          duration: 30000,
        });
      },
      /**
       * Custom validation to validate AM Best Rating Date only when a non-'--' AM Best rating is picked.
       *
       * @param {object} rule
       * @param {string} value
       * @param {Function} callback
       */
      validateAmBestRatingDate(rule, value, callback) {
        if (this.localCarrier.am_best_rating !== '--' && !value) {
          callback(new Error('AM Best date is required.'));
        }

        callback();
      },
      /**
       * Custom validation to validate AMB Number
       *
       * @param {object} rule
       * @param {string} value
       * @param {Function} callback
       */
      validateAmbNumber(rule, value, callback) {
        const regex = /^(^$|\d{6}|null)$/;

        if (!regex.test(this.localCarrier.amb_number)) {
          callback(new Error('AMB # must be 6 digits or blank.'));
        }

        callback();
      },
    },
  };
</script>

<style lang="scss" scoped>
.el-form-item {
  max-width: 300px;
}

:deep() .vdp-datepicker {
  > div:first-child {
    display: flex;
    align-items: center;
  }

  input {
    padding: 0 10px;
    height: 26px;
    line-height: 26px;
    font-size: 14px;
    border: 1px solid var(--tf-gray-medium);
  }

  &__calendar {
    margin-top: 10px;
  }

  &__clear-button {
    margin-left: 10px;

    i {
      font: {
        size: 24px;
        style: normal;
        weight: 700;
      }
      color: var(--tf-danger);
      line-height: 20px;
    }
  }
}

.el-form-item--mini.el-form-item {
  margin-bottom: 0;
}

label {
  font-size: 14px;
  display: block;
  margin-bottom: 10px;
}

:deep() .is-required .el-form-item__label {
  &:before {
    display: none;
  }
}

:deep(.cell.day) {
  border: transparent;
}

:deep(.el-form-item__error) {
  position: relative;
}
</style>
