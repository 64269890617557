<template>
  <div
    v-loading="!isLoaded"
    class="table-product-types"
  >
    <div class="btn-group align-end add-product-button">
      <AppButton
        data-test="add new product type"
        icon="fa-solid fa-plus"
        text="Add new product type"
        @click="addNewProductTypeRow"
      />
    </div>
    <CpTable
      v-if="isLoaded && !error"
      :columns="columns"
      :rows="rows"
      :css-vars="{
        '--cp-table-columns': 'minmax(100px, 250px) 100px minmax(200px, 1fr) 200px',
      }"
      data-test="product types table"
    >
      <template #empty>
        <CpTableRow
          :data-test="!rows.length ? `empty product type row` : `product type row`"
          class="cp-table__empty"
        >
          There are no product types set up yet.
        </CpTableRow>
      </template>
      <template
        v-if="rows.length"
        #row="{ row }"
      >
        <NewProductType
          v-if="!row.category_name"
          :key="`new-row-col-${row.id}`"
          :available-product-types="availableProductTypes"
          :row-key="row.id"
          :states-list="statesList"
          @save="addNewProductType"
          @delete="deleteNewProductTypeRow"
        />
        <ProductTypeRow
          v-else
          :key="`row-col-${row.id}`"
          :data-test="`${row.category_name} product type`"
          :product-type="row"
          :states-list="statesList"
          @updateStates="updateProductTypeStates"
          @updateCertified="updateProductTypeCertified"
          @delete="removeProductType"
          @move="openMoveProductTypeModal"
        />
      </template>
    </CpTable>
    <AppAlert
      v-else-if="error"
      :closable="false"
      :description="error"
      data-test="product type loading error"
      type="danger"
    />
    <MoveProductTypeModal
      v-if="isProductTypeModalVisible"
      :product-type="selectedProductType"
      :visible.sync="isProductTypeModalVisible"
      data-test="move product type confirmation"
      @removeProductType="updateAvailableProductTypes"
    />
  </div>
</template>

<script>
  import { useManagementCarriersStore } from '@/stores/CarrierManagement/carriers.js';
  import { mapWritableState } from 'pinia';
  import NewProductType from '@/components/Management/NewProductType.vue';
  import { CpTable, CpTableRow } from '@watchtowerbenefits/cp-components';
  import ProductTypeRow from '@/components/Management/ProductTypeRow.vue';
  import MoveProductTypeModal from '@/components/CarrierManagement/Modal/MoveProductTypeModal.vue';
  import ManagementCarrierService from '@/services/CarrierManagement/carrier.js';
  import ManagementProductService from '@/services/CarrierManagement/product.js';

  /**
   * A view that allows a user to add, delete, and move product types for a given carrier.
   *
   * @exports ProductTypes
   */
  export default {
    name: 'ProductTypes',
    components: {
      CpTable,
      CpTableRow,
      NewProductType,
      ProductTypeRow,
      MoveProductTypeModal,
    },
    props: {
      carrierId: {
        type: [Number, String],
        default: null,
      },
    },
    data: () => ({
      allProductTypes: [],
      isEditingStates: [],
      error: null,
      isLoaded: false,
      newProductTypeRows: [],
      statesList: [],
      offeredProductTypes: [],
      isProductTypeModalVisible: false,
      selectedProductType: {},
      columns: [
        {
          label: 'Product Type',
          id: 'category_name',
        },
        {
          label: 'Certified',
          id: 'certified',
        },
        {
          label: 'States',
          id: 'states',
        },
        {
          label: '',
          id: 'blank',
        },
      ],

    }),
    computed: {
      ...mapWritableState(useManagementCarriersStore, ['currentCarrierId']),
      /**
       * Returns an array of product types based on if the
       * product type is in not in the carrier's current offerings
       *
       * @returns {Array}
       */
      availableProductTypes() {
        return this.allProductTypes.filter(
          (productType) => !this.offeredProductTypes.map(({ id }) => id).includes(productType.id),
        );
      },
      /**
       * Returns an array of product types based on if the
       * product type is in not in the carrier's current offerings
       *
       * @returns {Array}
       */
      rows() {
        return [...this.newProductTypeRows, ...this.offeredProductTypes];
      },
    },
    /**
     * Fetch all product types, states, and carrier product types and load data correctly.
     */
    created() {
      this.currentCarrierId = this.carrierId;

      const getProductTypesPromise = ManagementProductService.getProductTypes()
        .then(({ product_types: productTypes }) => {
          this.$set(this, 'allProductTypes', productTypes);
        })
        .catch(() => {
          this.error = 'Error getting product types.';
        });
      const getStatesListPromise = ManagementCarrierService
        .getStatesList()
        .then(({ states }) => {
          this.statesList = states;
        })
        .catch(() => {
          this.error = 'Error getting states list.';
        });
      /**
       * The call to get ALL carriers does not include the offered_product_types key
       * so we need to fetch the individual carrier record on created to get those
       * product types.
       */
      const getCarrierProductTypesPromise = ManagementCarrierService
        .getCarrier(this.carrierId)
        .then(({ carrier }) => {
          this.$set(this, 'offeredProductTypes', carrier.offered_product_types);
        })
        .catch(() => {
          this.showToast('Could not retrieve carrier.');
        });

      Promise.all([
        getStatesListPromise,
        getProductTypesPromise,
        getCarrierProductTypesPromise,
      ])
        .then(() => { this.isLoaded = true; })
        .catch(() => { });
    },
    methods: {
      /**
       * Add new product row
       */
      addNewProductTypeRow() {
        this.newProductTypeRows.push({ id: this.newProductTypeRows.length + 1 });
      },
      /**
       * Delete new product row
       *
       * @param {number} rowKey
       */
      deleteNewProductTypeRow(rowKey) {
        this.newProductTypeRows.splice(this.newProductTypeRows.indexOf(rowKey), 1);
      },
      /**
       * Add new product type to the carrier record
       *
       * @param {number} index
       */
      addNewProductType({ rowKey, newProductType }) {
        ManagementCarrierService.addNewProductType(this.carrierId, newProductType)
          .then(({ carrier }) => {
            /**
             * After updating a carrier we update the list of offeredProductTypes
             */
            this.$set(this, 'offeredProductTypes', carrier.offered_product_types);
            this.newProductTypeRows.splice(this.newProductTypeRows.indexOf(rowKey), 1);
          })
          .catch(() => {
            this.showToast('Could not add carrier product.');
          });
      },
      /**
       * Update product type
       *
       * @param {object} payload
       * @param {number} payload.productTypeId
       * @param {boolean} payload.certified
       */
      updateProductTypeCertified({ productTypeId, certified }) {
        const productType = this.offeredProductTypes.find(({ id }) => productTypeId === id);

        ManagementCarrierService.updateProductType(this.carrierId, {
          ...productType,
          certified,
        })
          .then(({ carrier }) => {
            /**
             * After updating a carrier we update the list of offeredProductTypes
             */
            this.$set(this, 'offeredProductTypes', carrier.offered_product_types);
          })
          .catch(() => {
            this.showToast('Could not update carrier product.');
          });
      },
      /**
       * Update product type states list
       *
       * @param {object} payload
       * @param {number} payload.productTypeId
       * @param {Array} payload.states
       */
      updateProductTypeStates({ productTypeId, states }) {
        const productType = this.offeredProductTypes.find(({ id }) => productTypeId === id);
        const updatedProductType = {
          ...productType,
          states,
        };

        ManagementCarrierService.updateProductType(this.carrierId, updatedProductType)
          .then(({ carrier }) => {
            /**
             * After updating a carrier we update the list of offeredProductTypes
             */
            this.$set(this, 'offeredProductTypes', carrier.offered_product_types);
          })
          .catch(() => {
            this.showToast('Could not update carrier product.');
          });
      },
      /**
       * Remove product type
       *
       * @param {number} productTypeId
       */
      removeProductType(productTypeId) {
        // eslint-disable-next-line no-alert
        const confirmation = window.confirm('Remove product. Are you sure?');

        if (confirmation) {
          ManagementCarrierService.deleteProductType(this.carrierId, productTypeId)
            .then(({ carrier }) => {
              /**
               * After updating a carrier we update the list of offeredProductTypes
               */
              this.$set(this, 'offeredProductTypes', carrier.offered_product_types);
            })
            .catch(() => {
              this.showToast('Could not remove carrier product.');
            });
        }
      },
      /**
       * opens the modal and sends the data the modal needs
       *
       * @param {object} productTypeToMove
       */
      openMoveProductTypeModal(productTypeToMove) {
        this.selectedProductType = productTypeToMove;
        this.isProductTypeModalVisible = true;
      },
      /**
       * Removes the Moved Product Type from the list of available product types for this carrier
       *
       * @param {string|number} productTypeId
       */
      updateAvailableProductTypes(productTypeId) {
        this.offeredProductTypes = this.offeredProductTypes
          .filter(({ id }) => +id !== +productTypeId);
      },
      /**
       * Given a product id, gets the list of states
       *
       * @param {number} productId
       * @returns {Array}
       */
      getProductStates(productId) {
        const product = this.offeredProductTypes.find(({ id }) => id === productId);

        return product ? product.states : [];
      },
      /**
       * Show a "toast" error
       *
       * @param {string} message
       * @param {string} type
       */
      showToast(message, type = 'error') {
        this.$message({
          showClose: true,
          message,
          type,
          duration: 3000,
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .el-select {
    width: 100%;
  }

  .el-alert--error {
    margin: 0;
  }

  .add-product-button {
    margin-bottom: 20px;
  }

  .table-product-types {
    position: relative;
  }

  .states-read-only {
    display: flex;

    .states {
      margin-right: 10px;
    }
  }

  .states-editing {
    display: flex;
    justify-content: space-between;

    > span {
      flex: 1;
      margin-right: 10px;
    }
  }
</style>
<style>
  .states-multi-select-popover {
    height: 200px;
    overflow: scroll;
  }
</style>
