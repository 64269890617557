const subroutines = [
  {
    name: 'Center on Page',
    boolean: true,
    script: `
        Sub CenterOnPage()
            Dim ws As Worksheet
            Dim wb As Workbook

            For Each ws In wb.Sheets
                If ws.Visible = xlSheetVisible Then
                    With ws.PageSetup
                        .CenterHorizontally = {{Center Horizontally (True / False)}}
                        .CenterVertically = {{Center Vertically (True / False)}}
                    End With
                End If
            Next ws
        End Sub
    `,
  },
  {
    name: 'Column Width',
    script: `
        Sub ColumnWidth()
            Dim ws As Worksheet
            Dim wb As Workbook
            Dim col As Range
            Dim lastCol As Long
            Dim newWidth As Double
            
            For Each ws In wb.Worksheets
                If ws.Visible = xlSheetVisible Then
                    lastCol = ws.Cells(2, ws.Columns.Count).End(xlToLeft).Column
                    
                    For Each col In ws.Range(ws.Columns(2), ws.Columns(lastCol)).Columns
                        newWidth = col.ColumnWidth * {{Decimal Number}}
                        col.ColumnWidth = newWidth
                    Next col
                End If
            Next ws
        End Sub
    `,
  },
  {
    name: 'Copy and Hide Rows',
    script: `
        Sub CopyAndHideRows()
            Dim ws As Worksheet
            Dim wb As Workbook
            Dim lastRow As Long
            Dim i As Long
            Dim financialRow As Long
            Dim eligibilityRow As Long
            Dim networkRow As Long
            Dim sobRow As Long
            Dim scrow As Long

            If WorksheetExists(wb, "Stop Loss") Then
                CopyAndHideRowsForSheet wb, "Stop Loss", "FINANCIAL", "SPECIFIC CONTRACT"
            End If

            If WorksheetExists(wb, "Dental") Then
                CopyAndHideRowsForSheet wb, "Dental", "FINANCIAL", "ELIGIBILITY"
            End If

            If WorksheetExists(wb, "Vision") Then
                CopyAndHideRowsForSheet wb, "Vision", "FINANCIAL", "ELIGIBILITY"
            End If

            If WorksheetExists(wb, "Basic Life & AD&D") Then
                CopyAndHideRowsForSheet wb, "Basic Life & AD&D", "FINANCIAL", "ELIGIBILITY"
            End If

            If WorksheetExists(wb, "Basic Dependent Life") Then
                CopyAndHideRowsForSheet wb, "Basic Dependent Life", "FINANCIAL", "SCHEDULE OF BENEFITS"
            End If

            If WorksheetExists(wb, "Voluntary Life") Then
                CopyAndHideRowsForSheet wb, "Voluntary Life", "FINANCIAL", "ELIGIBILITY"
            End If

            If WorksheetExists(wb, "Voluntary Life & AD&D") Then
                CopyAndHideRowsForSheet wb, "Voluntary Life & AD&D", "FINANCIAL", "ELIGIBILITY"
            End If

            If WorksheetExists(wb, "Voluntary AD&D") Then
                CopyAndHideRowsForSheet wb, "Voluntary AD&D", "FINANCIAL", "ELIGIBILITY"
            End If

            If WorksheetExists(wb, "Short Term Disability") Then
                CopyAndHideRowsForSheet wb, "Short Term Disability", "FINANCIAL", "ELIGIBILITY"
            End If

            If WorksheetExists(wb, "ASO STD") Then
                CopyAndHideRowsForSheet wb, "ASO STD", "FINANCIAL", "ELIGIBILITY"
            End If

            If WorksheetExists(wb, "NY DBL") Then
                CopyAndHideRowsForSheet wb, "NY DBL", "FINANCIAL", "SCHEDULE OF BENEFITS"
            End If

            If WorksheetExists(wb, "NJ TDB") Then
                CopyAndHideRowsForSheet wb, "NJ TDB", "FINANCIAL", "SCHEDULE OF BENEFITS"
            End If

            If WorksheetExists(wb, "HI TDI") Then
                CopyAndHideRowsForSheet wb, "HI TDI", "FINANCIAL", "SCHEDULE OF BENEFITS"
            End If

            If WorksheetExists(wb, "Absence Management") Then
                CopyAndHideRowsForSheet wb, "Absence Management", "FINANCIAL", "FMLA ADMINISTRATION"
            End If

            If WorksheetExists(wb, "Long Term Disability") Then
                CopyAndHideRowsForSheet wb, "Long Term Disability", "FINANCIAL", "ELIGIBILITY"
            End If

        End Sub

        Sub CopyAndHideRowsForSheet(wb As Workbook, sheetName As String, startMarker As String, endMarker As String)
            Dim ws As Worksheet
            Dim lastRow As Long
            Dim i As Long
            Dim startRow As Long
            Dim endRow As Long

            Set ws = wb.Sheets(sheetName)
            ws.Copy After:=wb.Sheets(wb.Sheets.Count)

            lastRow = ws.Cells(ws.Rows.Count, 1).End(xlUp).Row

            For i = 1 To lastRow
                If UCase(ws.Cells(i, 1).Value) = UCase(startMarker) Then
                    startRow = i
                ElseIf UCase(ws.Cells(i, 1).Value) = UCase(endMarker) Then
                    endRow = i
                End If
            Next i

            If startRow > 0 And endRow > 0 Then
                ws.Rows(startRow & ":" & endRow - 1).EntireRow.Hidden = True
            End If

            ' Copy the new sheet with (2) suffix
            Dim newSheet As Worksheet
            Set newSheet = wb.Sheets(wb.Sheets.Count)
            newSheet.Name = ws.Name & " (2)"

            lastRow = newSheet.Cells(newSheet.Rows.Count, 1).End(xlUp).Row

            For i = 1 To lastRow
                If UCase(newSheet.Cells(i, 1).Value) = UCase(endMarker) Then
                    endRow = i
                End If
            Next i

            If endRow > 0 Then
                newSheet.Rows(endRow & ":" & lastRow).EntireRow.Hidden = True
            End If

            newSheet.Move After:=ws
        End Sub


        Function WorksheetExists(wb As Workbook, sheetName As String) As Boolean
            On Error Resume Next
            WorksheetExists = Not wb.Sheets(sheetName) Is Nothing
            On Error GoTo 0
        End Function
    `,
  },
  {
    name: 'Font Name',
    script: `
        Sub FontName()
            Dim ws As Worksheet
            Dim wb As Workbook

            For Each ws In wb.Sheets
                ws.Cells.Font.Name = "{{Font name}}"
            Next ws
        End Sub
    `,
  },
  {
    name: 'Font Size',
    script: `
        Sub FontSize()
            Dim ws As Worksheet
            Dim wb As Workbook

            For Each ws In wb.Sheets
                ws.Cells.Font.Size = {{Font size}}
            Next ws
        End Sub
    `,
  },
  {
    name: 'Margins',
    script: `
        Sub Margins()
            Dim ws As Worksheet
            Dim wb As Workbook
            
            marginSize = Application.InchesToPoints({{Decimal Number}})
            
            For Each ws In wb.Worksheets
                With ws.PageSetup
                    .TopMargin = marginSize
                    .BottomMargin = marginSize
                    .LeftMargin = marginSize
                    .RightMargin = marginSize
                End With
            Next ws
            
        End Sub
    `,
  },
  {
    name: 'Portrait',
    script: `
        Sub Portrait()
            Dim ws As Worksheet
            Dim wb As Workbook
            
            For Each ws In wb.Worksheets
                With ws.PageSetup
                    .Orientation = xlPortrait
                    .Zoom = False
                    End With
                
                ws.PageSetup.PrintTitleColumns = ws.Columns("A:A").Address
                
            Next ws
            
        End Sub
    `,
  },
  {
    name: 'Print 1x1',
    script: `
        Sub Print1x1()
            Dim ws As Worksheet
            Dim wb As Workbook
            
            For Each ws In wb.Sheets
                If ws.Visible = xlSheetVisible Then
                    With ws.PageSetup
                        .Zoom = False
                        .FitToPagesTall = 1
                        .FitToPagesWide = 1
                    End With
                End If
            Next ws
        End Sub
    `,
  },
  {
    name: 'Print Scale',
    script: `
        Sub PrintScale()
            Dim ws As Worksheet
            Dim wb As Workbook
            
            For Each ws In wb.Worksheets
                If ws.Visible = xlSheetVisible Then
                    ws.PageSetup.Zoom = {{ Decimal Number}}
                End If
            Next ws
        End Sub
    `,
  },
  {
    name: 'Remove Footers',
    script: `
        Sub RemoveFooters()
            Dim ws As Worksheet
            Dim wb As Workbook
            For Each ws In wb.Sheets
                If ws.Visible = xlSheetVisible Then
                    With ws.PageSetup
                        .CenterFooter = ""
                        .LeftFooter = ""
                        .RightFooter = ""
                    End With
                End If
            Next ws
        End Sub
    `,
  },
  {
    name: 'Remove Headers',
    script: `
        Sub RemoveHeaders()
            Dim ws As Worksheet
            Dim wb As Workbook
            For Each ws In wb.Sheets
                If ws.Visible = xlSheetVisible Then
                    With ws.PageSetup
                        .CenterHeader = ""
                        .LeftHeader = ""
                        .RightHeader = ""
                    End With
                End If
            Next ws
        End Sub
    `,
  },
  {
    name: 'Reset Page Breaks',
    script: `
        Sub ResetPageBreaks()
            Dim ws As Worksheet
            Dim wb As Workbook
            
            For Each ws In wb.Worksheets
                ws.ResetAllPageBreaks
            Next ws
            
        End Sub
    `,
  },
  {
    name: 'Row Height',
    script: `
        Sub RowHeight()
            Dim ws As Worksheet
            Dim wb As Workbook
            Dim row As Range
            Dim lastRow As Long
            Dim newHeight As Double
            
            Set wb = ThisWorkbook ' Set your workbook reference
            
            For Each ws In wb.Worksheets
                If ws.Visible = xlSheetVisible Then
                    lastRow = ws.Cells(ws.Rows.Count, 1).End(xlUp).Row
                    
                    ' Loop through rows starting from row 2 to the last used row
                    For Each row In ws.Rows("2:" & lastRow)
                        newHeight = row.RowHeight * {{Decimal Number}}
                        row.RowHeight = newHeight
                    Next row
                End If
            Next ws
        End Sub
    `,
  },
];

export default subroutines;
