import axios from 'axios';
import { config } from '@/utils/config.js';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';

const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;

/**
 * Formats endpoint by replacing path parameters.
 *
 * @param {string} endpoint
 * @param {object} params
 * @returns {string}
 */
function formatEndpoint(endpoint, params) {
  const formattedEndpoint = endpoint.replace(/:\w+/g, (match) => {
    const key = match.substring(1);

    return params[key];
  });

  return `${config.VUE_APP_API_URL}/${formattedEndpoint}`;
}

/**
 * Makes Axios request given the endpoint, params, and method.
 *
 * @param {string} endpoint
 * @param {object} params
 * @param {string} method
 * @returns {object}
 */
export async function makeRequest(endpoint, params, method) {
  const axiosConfig = getAxiosConfig(cookieNameSpace);
  const formattedEndpoint = formatEndpoint(endpoint, params);
  const hasData = method !== 'GET' && Object.keys(params).length > 1;
  const data = hasData ? params : null;
  const axiosMethod = method.toLowerCase();

  try {
    const response = data
      ? await axios[axiosMethod](formattedEndpoint, data, axiosConfig)
      : await axios[axiosMethod](formattedEndpoint, axiosConfig);

    return response;
  } catch (error) {
    return error.response;
  }
}
