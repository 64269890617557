import Vue from 'vue';
import {
  createPinia,
  PiniaVuePlugin,
} from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';
import { getUserInfo, setSignedIn, signOut } from '@/services/account.js';
// long term goal is to move to named exports for everything to lighten the load
import { getHealthCheck } from '@/services/healthCheck.js';
import {
  initializeLaunchDarkly,
  parseLaunchDarklyConfiguration,
} from '@/utils/launchDarkly.js';
import { config } from '@/utils/config.js';
import { useCoreApiStore } from '@/stores/coreApi.js';
import { useLaunchDarklyStore } from '@/stores/launchDarkly.js';
// logrocket
import LogRocket from 'logrocket';

// TODO Remove these icons when we've transitioned completely to font-awesome
import Icon from 'vue-awesome/components/Icon.vue';
import 'vue-awesome/icons/angle-up.js';
import 'vue-awesome/icons/caret-down.js';
import 'vue-awesome/icons/caret-up.js';
import 'vue-awesome/icons/check.js';
import 'vue-awesome/icons/check-circle.js';
import 'vue-awesome/icons/chevron-down.js';
import 'vue-awesome/icons/eye.js';
import 'vue-awesome/icons/plus.js';
import 'vue-awesome/icons/minus.js';
import 'vue-awesome/icons/times.js';
import 'vue-awesome/icons/angle-left.js';
import 'vue-awesome/icons/angle-right.js';

import {
  Threeflow,
} from '@watchtowerbenefits/shared-components';
import {
  getCookie,
  initializeSegment,
} from '@watchtowerbenefits/es-utils-public';
import {
  LfcModal,
  LfcApiTable,
  LfcApiTableHead,
  LfcApiTableParentRow,
  LfcApiTablePagination,
  LfcDisplayToast,
} from '@watchtowerbenefits/lfc-components';

import VueDraggable from 'vue-draggable';
import {
  init as SentryInit,
  BrowserTracing as SentryBrowserTracing,
  setContext as SentrySetContext,
  vueRouterInstrumentation as SentryVueRouterInstrumentation,
} from '@sentry/vue';
import { canUseThirdParty } from '@/utils/general.js';
import { activateAxios } from '@/utils/apiClient.js';
import { logRocketPlugin } from './utils/piniaPlugins.js';

import App from './App.vue';
import router from './router.js';

import Interceptor from './services/interceptor.js';
import './assets/styles/main.scss';
import '@watchtowerbenefits/lfc-components/dist/style.css';

let healthCheck = false;
let launchDarklyConfig;
const {
  VUE_APP_VERSION: appVersion,
  VUE_APP_ENV: appEnvironment,
} = config;

Vue.config.productionTip = false;

// eslint-disable-next-line vue/multi-word-component-names
Vue.component('Icon', Icon);
Vue.component('LfcModal', LfcModal);
Vue.component('LfcApiTable', LfcApiTable);
Vue.component('LfcApiTableHead', LfcApiTableHead);
Vue.component('LfcApiTableParentRow', LfcApiTableParentRow);
Vue.component('LfcApiTablePagination', LfcApiTablePagination);

// Set up axios defaults and request/response interceptors.
activateAxios();

Vue.mixin(LfcDisplayToast);
Interceptor.activate();
Vue.use(Threeflow, {
  inactiveLogout: Interceptor.inactiveSignOut,
});
Vue.use(VueDraggable);
Vue.use(PiniaVuePlugin);
const pinia = createPinia();

pinia.use(piniaPluginPersistedstate);
pinia.use(logRocketPlugin);

// some third party services we only want to run in non-features environments
const isNonFeaturesEnvironment = config.analyticsEnabled(['production', 'staging', 'qa']);

// Use log rocket and segment for certain application environments and local analytics mode.
// Push sentry logs for non local environments.
if (isNonFeaturesEnvironment || canUseThirdParty('logRocket')) {
  LogRocket.init('r2e0ub/admin-site', {
    console: {
      shouldAggregateConsoleErrors: true,
    },
    network: {
      requestSanitizer: (request) => {
        // if the request payload contains 'password'
        if (request.body && request.body.indexOf('password') !== -1) {
          // Scrub the request body
          // eslint-disable-next-line no-param-reassign
          request.body = null;
        }

        // return the request
        return request;
      },
    },
  });

  LogRocket.getSessionURL((sessionURL) => {
    // send the LogRocket url to sentry so we can attach it to tickets
    if (isNonFeaturesEnvironment || canUseThirdParty('sentry')) {
      SentrySetContext('LogRocket', { sessionURL });
    }

    // When you generate the LogRocket session url, we call the Axios Interceptor to pass it on each network call
    Interceptor.addLogRocketURLToHeaders(sessionURL);
  });

  /* cSpell:disable */
  initializeSegment(config.VUE_APP_SEGMENT_TOKEN);
  /* cSpell:enable */

  if (isNonFeaturesEnvironment || canUseThirdParty('sentry')) {
    SentryInit({
      Vue,
      dsn: 'https://f1c77e30cbbc470bbb2572840a29a80d@o97018.ingest.sentry.io/1436076',
      ignoreErrors: [
        // ignore common non-breaking issues
        /request failed with status code 401/i,
        /a network error occurred/i,
        'NavigationDuplicated',
      ],
      integrations: [new SentryBrowserTracing({
        routingInstrumentation: SentryVueRouterInstrumentation(router),
        tracePropagationTargets: ['threeflow.com', /^\//],
        logErrors: true,
      })],
      environment: appEnvironment,
      release: `admin_ui-${appEnvironment}@${appVersion}`,
      beforeSend: (event, { captureContext }) => {
        // we want to stop 401 errors from being sent to sentry
        const has401Status = captureContext
          && captureContext.error
          && captureContext.error.response
          && captureContext.error.response.status === 401;

        // returning null discards the event entirely
        return has401Status ? null : event;
      },
    });
  }
}

try {
  const response = await getHealthCheck();

  // HealthCheck is the only endpoint that's not parsed by the interceptor,
  // because, when the interceptor fires, Vue isn't initialized yet.
  // So, we slightly double it up and do it here instead.
  healthCheck = true;
  launchDarklyConfig = parseLaunchDarklyConfiguration(response);
} catch {
  // If the health check fails, then we can't initialize LaunchDarkly because we
  // don't have the clientSideId and userInfo required to initialize LD in Secure
  // Mode with an anonymous user but we still need featureFlag values, so we pass
  // "flagsStub" to initializeLaunchDarkly, thus still serving default flag values
  // as defined in the featureFlags file.
  healthCheck = false;
  launchDarklyConfig = { flagStub: true };
} finally {
  initializeLaunchDarkly(launchDarklyConfig);
  new Vue({
    router,
    pinia,
    async created() {
      const cookieNameSpace = config.VUE_APP_COOKIE_NAMESPACE;
      const authToken = getCookie(`${cookieNameSpace}-auth-token`);
      const matchedRoute = router.history.current.matched[0];
      const userId = getCookie(`${cookieNameSpace}-user-id`);
      const pushToSignIn = () => {
        this.$router.push({ name: 'SignInPage' });
      };
      const launchDarklyStore = useLaunchDarklyStore();
      const coreApiStore = useCoreApiStore();

      coreApiStore.isHealthy = healthCheck;
      launchDarklyStore.config = launchDarklyConfig;

      if (authToken && userId) {
        try {
          const data = await getUserInfo();

          setSignedIn(data);
        } catch {
          signOut();
          pushToSignIn();
        }
      } else {
        window.localStorage.removeItem('auth');
        window.localStorage.removeItem('confirmed');

        if (matchedRoute && matchedRoute.meta.requireAuth) {
          pushToSignIn();
        }
      }
    },
    render: (h) => h(App),
  }).$mount('#app');
}

if (window.Cypress) {
  // Add setSignedIn function to window.Cypress as persistMockSignIn so that the mockSignIn command can properly set localStorage and cookies
  window.Cypress.persistMockSignIn = setSignedIn;
}
