<template>
  <div class="carrier-content management-content">
    <ManagementHeader
      :breadcrumb="{ name: 'CarrierManagement' }"
      breadcrumb-text="Back to carriers"
      data-test="carrier header"
    >
      Add New Carrier
    </ManagementHeader>

    <TfTabs
      value="EditCarrierSettings"
    >
      <ElTabPane
        v-for="({ disabled, label, name }) in panes"
        :key="label"
        :name="name"
        :disabled="disabled"
      >
        <template slot="label">
          <span :data-test="`to ${name}`">
            {{ label }}
          </span>
        </template>
      </ElTabPane>
    </TfTabs>
    <RouterView />
  </div>
</template>

<script>
// components
  import ManagementHeader from '@/components/Management/Header.vue';

  /**
   * "Add new Carrier" view
   *
   * @exports AddCarrier
   */
  export default {
    name: 'AddCarrier',
    components: {
      ManagementHeader,
    },
    props: {
      carrierId: {
        type: [Number, String],
        default: null,
      },
    },
    data() {
      return {
        carrier: null,
        panes: [
          {
            label: 'Users',
            name: 'EditCarrierUsers',
            disabled: true,
          },
          {
            label: 'Email domains',
            name: 'EditCarrierEmails',
            disabled: true,
          },
          {
            label: 'Product types',
            name: 'EditCarrierProductTypes',
            disabled: true,
          },
          {
            label: 'Settings',
            name: 'EditCarrierSettings',
          },
        ],
      };
    },
    computed: {
      /**
       * Name
       *
       * @returns {string}
       */
      tabValue() {
        return this.$route.name;
      },
    },
  };
</script>

<style lang="scss">
  .carrier-content .el-tabs__content {
    margin-top: 40px;
  }
</style>
