<template>
  <div>
    <h1>Support tools</h1>
    <section v-if="canViewSupportRequests">
      <AppButton
        v-for="(value, key) in supportRequests"
        :key="key"
        class="submit-button"
        @click="openModal(key)"
      >
        {{ value.title }}
      </AppButton>
    </section>
    <section>
      <AppButton
        type="primary"
        icon="fa-duotone fa-solid fa-file-export"
        text="CEFT"
        @click="openMacroModal"
      />
    </section>
    <RequestModal
      v-model="isRequestModalVisible"
      :parameters="requestData"
    />
    <MacroModal v-model="isMacroModalVisible" />
  </div>
</template>

<script>
  import RequestModal from '@/components/SupportTools/RequestModal.vue';
  import MacroModal from '@/components/SupportTools/MacroModal.vue';
  import { mapState } from 'pinia';
  import { useUserInfoStore } from '@/stores/userInfo.js';
  import supportRequests from '@/config//supportRequests/supportRequests.js';

  /**
   * A Dashboard for completing support requests.
   *
   * @vuedoc
   * @exports SupportTools
   * @category Views
   */
  export default {
    name: 'SupportTools',
    components: {
      RequestModal,
      MacroModal,
    },
    data() {
      return {
        isRequestModalVisible: false,
        isMacroModalVisible: false,
        requestData: {},
        supportRequests,
      };
    },
    computed: {
      ...mapState(useUserInfoStore, { userRoles: 'roles' }),
      /**
       * Whether or not the current user can view all support requests
       *
       * @returns {boolean}
       */
      canViewSupportRequests() {
        return this.userRoles.includes('support_user');
      },
    },
    methods: {
      openModal(modalType) {
        this.requestData = this.supportRequests[modalType];
        this.isRequestModalVisible = true;
      },
      openMacroModal() {
        this.isMacroModalVisible = true;
      },
    },
  };
</script>

<style scoped lang="scss">
  h1 {
    background-color: var(--tf-gray-light);
    padding: 10px 30px;
    border-bottom: 1px solid var(--tf-gray-medium);
  }

  section {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
    padding: 10px;
    justify-content: center;
  }
</style>
