<template>
  <div>
    <LfcUserAdmin
      user-type="broker"
      v-bind="{
        userId,
        userIsAdmin,
        roles: brokerRoles,
        brokerId,
        emailDomains: currentBroker.email_domains,
        provider: currentBroker.provider,
        checkInviteBrokerUserMethod,
        getUsersMethod: getBrokerUsersMethod,
        inviteBrokerUserMethod,
        updateUserMethod,
        customInviteMethod: true,
        addOnRoles,
      }"
      @inviteCtaClicked="$router.push({ name: 'BrokerInviteUsers' });"
    />
  </div>
</template>

<script>
  import { mapWritableState, mapState } from 'pinia';
  import { useBrokersStore } from '@/stores/brokers.js';
  import { useUserInfoStore } from '@/stores/userInfo.js';
  import { getBrokerUsers as getBrokerUsersMethod } from '@/services/broker.js';
  import UserService, {
    checkInviteBrokerUser as checkInviteBrokerUserMethod,
    inviteBrokerUser as inviteBrokerUserMethod,
  } from '@/services/user.js';
  import {
    LfcUserAdmin,
    adminRoles,
    brokerRoles,
    brokerAddOnRoles,
  } from '@watchtowerbenefits/lfc-components';

  /**
   * View/edit/add/delete users for a brokerage
   *
   * @vuedoc
   * @exports ManageBrokerUsers
   * @category Views
   */
  export default {
    name: 'ManageBrokerUsers',
    components: { LfcUserAdmin },
    props: {
      brokerId: {
        type: [Number, String],
        required: true,
      },
      userId: {
        type: [Number, String],
        default: null,
      },
    },
    data() {
      return {
        checkInviteBrokerUserMethod,
        getBrokerUsersMethod,
        inviteBrokerUserMethod,
        updateUserMethod: UserService.updateUser,
      };
    },
    computed: {
      ...mapState(useUserInfoStore, { userRoles: 'roles' }),
      ...mapState(useBrokersStore, ['currentBroker']),
      ...mapWritableState(useBrokersStore, ['currentBrokerId']),
      /**
       * Check if the user is an admin
       *
       * @returns {boolean}
       */
      userIsAdmin() {
        return this.userRoles.some((role) => role.includes('admin'));
      },
      /**
       * return the right set of broker roles depending on the context
       *
       * @returns {Array}
       */
      brokerRoles() {
        return Number(this.currentBrokerId) === 1
          ? [...brokerRoles, ...adminRoles]
          : [...brokerRoles];
      },
      /**
       * return the add-on roles
       *
       * @returns {Array}
       */
      addOnRoles() {
        return brokerAddOnRoles;
      },
    },
    created() {
      this.currentBrokerId = this.brokerId;
    },
  };
</script>
