<template>
  <div class="management-content">
    <header>
      <h1>Carrier Management</h1>
      <RouterLink :to="{ name: 'AddCarrier' }">
        <AppButton
          data-test="add new carrier"
          icon="fa-solid fa-plus"
          text="Add Carrier"
        />
      </RouterLink>
    </header>
    <CpTable
      v-bind="{
        columns,
        isLoading: !isLoaded,
        rows: carriers,
      }"
    >
      <template #row="{ row }">
        <CpTableRow>
          <CpTableCell class="carrier-row">
            <span data-test="carrier name">{{ row.name }}</span>
            <RouterLink
              :data-test="`edit ${row.name}`"
              :to="{
                name: 'EditCarrier',
                params: { carrierId: row.id },
              }"
            >
              <AppButton text="Edit" />
            </RouterLink>
          </CpTableCell>
        </CpTableRow>
      </template>
      <template #empty>
        <CpTableRow class="cp-table__empty">
          There don't appear to be any carriers.
        </CpTableRow>
      </template>
    </CpTable>
  </div>
</template>

<script>
  import { useManagementCarriersStore } from '@/stores/CarrierManagement/carriers.js';
  import { mapActions, mapState } from 'pinia';
  import {
    CpTable,
    CpTableRow,
    CpTableCell,
  } from '@watchtowerbenefits/cp-components';
  import ManagementCarrierService from '@/services/CarrierManagement/carrier.js';
  import { sortObjects } from '@watchtowerbenefits/es-utils-public';
  /**
   * View for the listing of Carriers.
   *
   * @exports CarrierManagement
   */
  export default {
    name: 'CarrierManagement',
    components: {
      CpTable,
      CpTableRow,
      CpTableCell,
    },
    data: () => ({
      isLoaded: false,
      sortBy: 'name',
      sortOrder: 'asc',
    }),
    computed: {
      ...mapState(useManagementCarriersStore, ['carriers']),
      /**
       * Column header information for the table
       *
       * @returns {Array}
       */
      columns() {
        return [{ label: 'Carrier Name', id: 'name' }];
      },
      /**
       * Carriers ordered by the sortBy and sortOrder
       *
       * @returns {Array}
       */
      orderedCarriers() {
        return sortObjects(this.carriers, this.sortBy, this.sortOrder);
      },
    },
    /**
     * Get all Carriers from Carrier Service and add them to our store
     */
    async created() {
      const { carriers } = await ManagementCarrierService.getCarriers();

      this.setCarriers(carriers);
      this.isLoaded = true;
    },
    methods: {
      ...mapActions(useManagementCarriersStore, ['setCarriers']),
    },
  };
</script>

<style scoped>
  header,
  .carrier-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  header {
    margin-bottom: 10px;
  }
</style>
