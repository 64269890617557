// Collection of objects that define endpoints to be used in SupportTools.vue

/*
Guidelines for adding a request to Support Tools:

- Each request needs:
  - A title
  - An endpoint starting with 'v1'
  - The request method in caps
  - An array of input objects (minimum 1)

- Each input needs:
  - A label (if required, please append a star * for UX)
  - A placeholder
  - A parameter ID

- Optional input params:
  - description: ''  Include to display additional info for an input
  - required: true   Include this to require that the input is not blank
  - array: true      Include this if the endpoint accepts an array for this param, otherwise it will fail
  - type: 'textarea' Changes input to large text input

- If the endpoint has a path param, like /:id/, the parameterId of that input must match the string in the endpoint attribute.
  - Example:
      endpoint:             'v1/admin/support/employers/:employer_id/merge_projects',
      parameterId of input: 'employer_id'
*/

const supportRequests = {
  mergeProjects: {
    title: 'Merge Projects',
    endpoint: 'v1/admin/support/employers/:employer_id/merge_projects',
    method: 'POST',
    inputs: [
      {
        label: 'Employer ID *',
        placeholder: '1999',
        parameterId: 'employer_id',
        validation_string: 'v1/broker_portal/employers/:employer_id',
        validation_parameter: 'employer_id',
        targeted_value: 'name',
        data_type: 'number',
        required: true,
      },
      {
        label: 'Project ID(s) to Merge *',
        description: 'The project(s) you want to be merged separated by commas',
        placeholder: '12345, 12345',
        parameterId: 'project_ids_to_merge',
        validation_string: 'v1/admin/projects/:id',
        validation_parameter: 'id',
        required: true,
        data_type: 'number',
        array: true,
      },
      {
        label: 'Project ID to Keep *',
        description: 'The project you want to keep',
        placeholder: '12345',
        parameterId: 'project_id_to_keep',
        validation_string: 'v1/admin/projects/:id',
        validation_parameter: 'id',
        data_type: 'number',
        required: true,
      },
      {
        label: 'User Email *',
        placeholder: 'johnny.appleseed@guardian.com',
        parameterId: 'user_email',
        data_type: 'email',
        required: true,
      },
    ],
  },
  getProjectInfo: {
    title: 'Get Project Info',
    endpoint: 'v1/admin/projects/:project_id',
    method: 'GET',
    inputs: [
      {
        label: 'Project ID *',
        description: 'Enter the ID of the project',
        placeholder: '12345',
        parameterId: 'project_id',
        validation_string: 'v1/admin/projects/:id',
        validation_parameter: 'id',
        data_type: 'number',
        required: true,
      },
    ],
  },
  undoCloseProduct: {
    title: 'Undo Close Product',
    endpoint: 'v1/admin/support/project_products/:id/undo_close_product',
    method: 'PATCH',
    inputs: [
      {
        label: 'Project Product ID *',
        description: 'Enter the Project Product ID',
        placeholder: '21425',
        parameterId: 'id',
        required: true,
      },
    ],
  },
  updateBroker: {
    title: 'Update Broker Disclosure/Privacy Policy',
    endpoint: 'v1/admin/brokers/:id',
    method: 'PATCH',
    inputs: [
      {
        label: 'Broker ID *',
        placeholder: '33',
        parameterId: 'id',
        validation_string: 'v1/admin/brokers/:id',
        validation_parameter: 'id',
        targeted_value: 'name',
        data_type: 'number',
        required: true,
      },
      {
        label: 'Disclosure',
        placeholder: 'This is a disclosure',
        parameterId: 'disclosure',
        type: 'textarea',
      },
      {
        label: 'Privacy Statement',
        placeholder: 'This is a privacy statement',
        parameterId: 'privacy_statement',
        type: 'textarea',
      },
    ],
  },
  transferCarrierCoverage: {
    title: 'Transfer Carrier Coverage',
    endpoint: 'v1/admin/support/employers/:employer_id/transfer_carrier_coverages',
    method: 'POST',
    inputs: [
      {
        label: 'Employer ID',
        placeholder: '5678',
        parameterId: 'employer_id',
        validation_string: 'v1/broker_portal/employers/:employer_id',
        validation_parameter: 'employer_id',
        targeted_value: 'name',
        required: true,
      },
      {
        label: 'From Carrier ID *',
        description: 'Carrier the product(s) are under now',
        placeholder: '60',
        parameterId: 'from_carrier_id',
        validation_string: 'v1/carrier_portal/admin/carriers/:id',
        validation_parameter: 'id',
        targeted_value: 'name',
        data_type: 'number',
        required: true,
      },
      {
        label: 'To Carrier ID *',
        description: 'Carrier the product(s) should be under',
        placeholder: '245',
        parameterId: 'to_carrier_id',
        validation_string: 'v1/carrier_portal/admin/carriers/:id',
        validation_parameter: 'id',
        targeted_value: 'name',
        data_type: 'number',
        required: true,
      },
      {
        label: 'Product Type IDs *',
        description: 'Array of product IDs to transfer, separated by commas',
        placeholder: '14, 15',
        parameterId: 'product_type_ids',
        data_type: 'number',
        required: true,
        array: true,
      },
      {
        label: 'Invitee User Email',
        description: 'If the destination carrier has users - will setup project invite so marketable projects are visiable within the "to_carrier" dashboard.',
        placeholder: 'johnnyappleseed@threeflow.com',
        data_type: 'email',
        parameterId: 'invitee_user_email',
      },
    ],
  },
  reopenRfpToMarket: {
    title: 'Reopen Marketing Project',
    endpoint: 'v1/admin/projects/:id/reopen_rfp_to_market',
    method: 'PATCH',
    inputs: [
      {
        label: 'Project ID *',
        description: 'Must be a marketing type event',
        placeholder: '12345',
        parameterId: 'id',
        validation_string: 'v1/admin/projects/:id',
        validation_parameter: 'id',
        data_type: 'number',
        required: true,
      },
    ],
  },
};

export default supportRequests;
