<template>
  <ElDialog
    :visible="visible"
    :before-close="onBeforeClose"
    top="70px"
    width="450px"
    data-test="confirm delete domain modal"
  >
    <h2>Delete this domain?</h2>
    <p>
      You are deleting <b>{{ row.domain }}</b>.
    </p>
    <div class="btn-group">
      <AppButton
        data-test="cancel delete domain"
        size="text"
        text="Cancel"
        @click="onBeforeClose"
      />
      <AppButton
        data-test="confirm delete domain"
        type="danger"
        icon="fa-solid fa-trash-can"
        size="large"
        text="Delete"
        @click="deleteDomain"
      />
    </div>
  </ElDialog>
</template>

<script>
  export default {
    name: 'ConfirmDeleteDomainModal',
    props: {
      visible: {
        type: Boolean,
        default: false,
      },
      row: {
        type: Object,
        default: () => ({}),
      },
    },
    methods: {
      /**
       * moves the product type
       */
      deleteDomain() {
        this.$emit('removeDomainConfirmed', this.row.id);
        this.$emit('update:visible', false);
      },
      /**
       * before the modal closes we need to update the visibility
       *
       */
      onBeforeClose() {
        this.$emit('update:visible', false);
      },
    },
  };
</script>

<style lang="scss" scoped>
:deep() {
  .el-dialog {
    &__header {
      border-bottom: none;
    }

    &__body {
      padding: 0 60px 60px;
      text-align: center;
    }

    .tf-button {
      margin-top: 30px;
    }
  }

  .el-select {
    width: 100%;
  }
}

label {
  display: block;
  width: 100%;
  font-weight: 700;
  text-align: left;
  margin: 15px 0 10px;
}
</style>
