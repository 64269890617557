import axios from 'axios';
import { getAxiosConfig } from '@watchtowerbenefits/es-utils-public';
import { config } from '@/utils/config.js';

const cookieNamespace = config.VUE_APP_COOKIE_NAMESPACE;
const axiosConfig = () => getAxiosConfig(cookieNamespace);

/**
 * All product related ajax calls
 *
 * @exports Product
 */
export default {
  /**
   * Get all the different product types for a Carrier to offer
   * used for Carrier Management
   *
   * @returns {Promise}
   */
  getProductTypes() {
    const url = `${config.VUE_APP_API_URL}/v1/admin/product_types`;

    return axios
      .get(url, axiosConfig())
      .then(({ data }) => data);
  },
};
