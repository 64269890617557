<template>
  <section>
    <div class="btn-group align-end add-domain-button">
      <AppButton
        :is-disabled="addingNewDomain"
        data-test="add new domain"
        icon="fa-solid fa-plus"
        @click="startAddingNewDomain"
      >
        Add new domain
      </AppButton>
    </div>
    <CpTable
      :columns="columns"
      :rows="rows"
    >
      <template #empty>
        <CpTableRow class="cp-table__empty">
          There are no domains configured.
        </CpTableRow>
      </template>
      <template
        v-if="rows.length"
        #row="{ row }"
      >
        <CpTableRow
          :key="`row-col-${row.id}`"
          :data-test="`domain ${row.domain}`"
        >
          <template v-if="row.domain">
            <CpTableCell>{{ row.domain }}</CpTableCell>
            <CpTableCell style="grid-column-end:none">
              <AppButton
                icon="fa-solid fa-trash-can"
                size="text-small"
                text="Remove domain"
                type="danger"
                :data-test="`remove domain ${row.domain}`"
                @click="openDeleteDomainModal(row)"
              />
            </CpTableCell>
          </template>
          <template v-else>
            <CpTableCell>
              <ElForm
                :model="currentCarrier"
                :rules="rules"
                label-position="top"
                @submit.native.prevent
              >
                <ElFormItem
                  prop="newDomain"
                  size="mini"
                >
                  <ElInput
                    v-model="newDomain"
                    name="domain-name-input"
                    placeholder="Enter Domain"
                    @keyup.enter.stop.native="addNewDomain"
                  />
                </ElFormItem>
              </elform>
            </CpTableCell>
            <CpTableCell style="grid-column-end:none">
              <div class="btn-group align-end">
                <AppButton
                  data-test="cancel add domain"
                  type="danger"
                  icon="fa-solid fa-xmark"
                  size="icon"
                  text="Cancel edit"
                  @click="clearNewDomain"
                />
                <AppButton
                  :is-disabled="!newDomain || !validDomain"
                  data-test="affirm domain"
                  type="success"
                  icon="fa-solid fa-check"
                  size="icon"
                  text="Confirm domain"
                  @click="addNewDomain"
                />
              </div>
            </CpTableCell>
          </template>
        </CpTableRow>
      </template>
    </CpTable>
    <ConfirmDeleteDomainModal
      :visible.sync="isDeleteModalVisible"
      :row="domainToRemove"
      @removeDomainConfirmed="removeDomain(domainToRemove)"
    />
  </section>
</template>

<script>
/**
 * "Manage domain names associated with a carrier" view
 *
 * @exports Domains
 */
  import { useManagementCarriersStore } from '@/stores/CarrierManagement/carriers.js';
  import { mapActions, mapWritableState } from 'pinia';
  import { CpTable, CpTableRow, CpTableCell } from '@watchtowerbenefits/cp-components';
  import { validateDomain } from '@watchtowerbenefits/es-utils-public';
  import ConfirmDeleteDomainModal from '@/components/CarrierManagement/Modal/ConfirmDeleteDomainModal.vue';
  import ManagementCarrierService from '@/services/CarrierManagement/carrier.js';

  export default {
    name: 'CarrierDomains',
    components: {
      CpTable,
      CpTableRow,
      CpTableCell,
      ConfirmDeleteDomainModal,
    },
    props: {
      carrierId: {
        type: [Number, String],
        default: null,
      },
    },
    data() {
      return {
        addingNewDomain: false,
        newDomainRows: [],
        newDomain: null,
        isDeleteModalVisible: false,
        domainToRemove: {},
        columns: [
          { label: 'Domain name', id: 'domain' },
        ],
        validDomain: false,
        rules: {
          newDomain: [
            { validator: this.checkDomain, trigger: 'change' },
          ],
        },
      };
    },
    computed: {
      ...mapWritableState(useManagementCarriersStore, ['currentCarrierId', 'currentCarrier']),
      rows() {
        return [
          ...this.newDomainRows,
          ...this.currentCarrier.email_domains.map((domain, index) => ({ id: index, domain })),
        ];
      },
    },
    /**
     * Sets the currentCarrier to the passed carrierId
     */
    created() {
      this.currentCarrierId = this.carrierId;
    },
    methods: {
      ...mapActions(useManagementCarriersStore, ['updateCarrier']),
      /**
       * starts adding a new domain
       */
      startAddingNewDomain() {
        this.addingNewDomain = true;
        this.newDomainRows.push({ id: this.rows.length + 1 });
      },
      /**
       * Add a domain to the carrier email_domains array
       */
      addNewDomain() {
        if (this.validDomain) {
          const updatedCarrier = {
            ...this.currentCarrier,
            email_domains: [...this.currentCarrier.email_domains, this.newDomain],
          };

          this.saveCarrier(updatedCarrier);
          this.$message({
            duration: 3000,
            message: `You have added ${this.newDomain}.`,
            showClose: true,
            type: 'success',
          });
        }
      },
      /**
       * Validator to make sure the domains are valid
       *
       * @param {object} rule
       * @param {string} value
       * @param {Function} callback
       */
      checkDomain(rule, value, callback) {
        const exists = this.currentCarrier.email_domains.some(
          (domain) => domain.toLowerCase() === this.newDomain.toLowerCase(),
        );

        if (exists) {
          this.validDomain = false;
          callback(new Error('This domain has already been entered.'));
        } else if (this.newDomain.match(/[A-Z]/g)) {
          this.validDomain = false;
          callback(new Error('Please enter a domain without any capital letters.'));
        } else if (validateDomain(this.newDomain.toLowerCase())) {
          this.validDomain = true;
          callback();
        } else {
          this.validDomain = false;
          callback(new Error('Please enter a valid domain.'));
        }
      },
      /**
       * Reset the new domain input
       */
      clearNewDomain() {
        this.newDomain = '';
        this.addingNewDomain = false;
        this.newDomainRows = [];
      },

      /**
       * Remove an already saved email domain
       *
       * @param {object} row
       */
      openDeleteDomainModal(row) {
        this.isDeleteModalVisible = true;
        this.domainToRemove = row;
      },
      /**
       * Remove an already saved email domain
       */
      removeDomain() {
        const { id } = this.domainToRemove;
        const emailDomains = [...this.currentCarrier.email_domains];
        const removedDomain = emailDomains[id];

        emailDomains.splice(id, 1);

        const updatedCarrier = {
          ...this.currentCarrier,
          email_domains: emailDomains,
        };

        this.saveCarrier(updatedCarrier);
        this.$message({
          duration: 3000,
          message: `You have removed ${removedDomain}.`,
          showClose: true,
          type: 'success',
        });
      },

      /**
       * Save the updated carrier record and update the store
       *
       * @param {object} value
       */
      saveCarrier(value) {
        ManagementCarrierService
          .saveCarrierInfo(this.carrierId, value)
          .then(() => {
            this.updateCarrier(value);
            this.currentCarrier = value;
            this.clearNewDomain();
            // todo add toasts
          })
          .catch(() => {
            this.$message({
              showClose: true,
              message: 'There was an error saving the carrier.',
              type: 'error',
              duration: 3000,
            });
          });
      },
    },
  };
</script>

<style scoped>
.add-domain-button {
  margin-bottom: 20px;
}
</style>
