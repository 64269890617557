<template>
  <div class="logo-upload" data-test="logo upload">
    <h4>{{ name }}</h4>
    <ElUpload
      :action="action"
      :class="{ 'has-logo': hasLogo }"
      :file-list="[{
        name,
        url: logoUrl,
      }]"
      :http-request="uploadLogoRequest"
      :on-preview="handlePreviewLogo"
      :on-remove="handleRemoveLogo"
      list-type="picture-card"
      name="logo[]"
    >
      <AppIcon icon="fa-solid fa-plus" />
    </ElUpload>
  </div>
</template>

<script>
/**
 * A Component used to upload, preview, and remove a logo.
 *
 * @exports Management/LogoUpload
 */
  export default {
    name: 'LogoUpload',
    props: {
      /**
       * The name/type of the logo rendered as a heading (e.g. 'Large logo')
       */
      name: {
        type: String,
        default: 'Logo',
      },
      /**
       * The API endpoint to upload the logo to.
       */
      action: {
        type: String,
        required: true,
      },
      /**
       * The URL of the logo to render.
       */
      logoUrl: {
        type: String,
        default: null,
      },
    },
    data: () => ({
      hasLogo: false,
    }),
    /**
     * Render the current logo in the template if a logoUrl.
     */
    created() {
      this.hasLogo = !!this.logoUrl;
    },
    methods: {
      /**
       * Open the logo in a new window
       *
       * @param {Event} event
       */
      handlePreviewLogo(event) {
        window.open(event.url);
      },
      /**
       * Remove the logo so a new one can be uploaded.
       * We do not update the record because there's risk to allowing
       * a record to not have a logo down the line (Broker, Carrier sites).
       */
      handleRemoveLogo() {
        this.hasLogo = false;
      },
      /**
       * The upload component wants to post images, so if you want to
       * do a patch call, we have to overwrite the request method.
       *
       * @param {Event} fileSelectionEvent
       */
      uploadLogoRequest(fileSelectionEvent) {
        this.$emit('updateLogo', {
          name: this.name,
          file: fileSelectionEvent.file,
        });

        this.hasLogo = true;
      },
    },
  };
</script>

<style lang="scss" scoped>
.logo-upload {
  display: inline-block;
  vertical-align: top;
  margin-right: 10px;
  width: 150px;
}

:deep() {
  .el-upload-list--picture-card .el-upload-list__item {
    display: flex;

    // stylelint-disable-next-line selector-max-compound-selectors
    img {
      height: auto;
      margin: auto 0;
    }
  }

  .el-upload-list__item {
    transition: none;
  }

  .el-upload-list {
    display: none;
  }

  .has-logo {
    .el-upload-list {
      display: block;
    }

    div.el-upload.el-upload--picture-card {
      display: none;
    }
  }

  .el-icon-close-tip {
    /* stylelint-disable declaration-no-important  */
    display: none !important;
    /* stylelint-enable declaration-no-important  */
  }
}
</style>
