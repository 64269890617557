<template>
  <div>
    <div class="btn-group align-end">
      <RouterLink
        :to="{
          name: 'AddCarrierUser',
          params: { orgId: carrierId },
        }"
      >
        <AppButton
          data-test="add user"
          icon="fa-solid fa-plus"
          text="Add new user"
        />
      </RouterLink>
    </div>
    <section v-loading="!isLoaded">
      <CpTable
        v-if="isLoaded"
        data-test="carrier users"
        :columns="columns"
        :rows="carrierUsers"
      >
        <template #empty>
          <CpTableRow class="cp-table__empty">
            <CpTableCell data-test="no users disclaimer">
              There are no users associated with this carrier.
            </CpTableCell>
          </CpTableRow>
        </template>
        <template #row="{ row }">
          <CpTableRow
            v-if="carrierUsers.length"
            :key="`row-col-${row.id}`"
          >
            <CpTableCell>
              <span data-test="carrier user name">
                {{ row.last_name }}, {{ row.first_name }}
              </span>
              <TfBadge
                v-if="row.active === false"
                data-test="deactivated user indicator"
                size="small"
                status="warn"
                value="Deactivated"
              />
            </CpTableCell>
            <CpTableCell style="grid-column-end:none">
              <RouterLink
                :to="{
                  name: 'EditCarrierUser',
                  params: { orgId: carrierId, userId: row.id },
                }"
              >
                <AppButton
                  class="add-user-button"
                  text="Edit"
                />
              </RouterLink>
            </CpTableCell>
          </CpTableRow>
        </template>
      </CpTable>
    </section>
  </div>
</template>

<script>
  import { mapState, mapWritableState } from 'pinia';
  import { useManagementCarriersStore } from '@/stores/CarrierManagement/carriers.js';
  import ManagementCarrierService from '@/services/CarrierManagement/carrier.js';
  import { CpTable, CpTableRow, CpTableCell } from '@watchtowerbenefits/cp-components';
  import { sortObjects } from '@watchtowerbenefits/es-utils-public';

  /**
   * A view that allows a user to view/edit/add/delete users for any given carrier.
   *
   * @exports ManageUsers
   */
  export default {
    name: 'ManageUsers',
    components: {
      CpTable,
      CpTableRow,
      CpTableCell,
    },
    props: {
      carrierId: {
        type: [Number, String],
        default: null,
      },
    },
    data: () => ({
      columns: [
        {
          label: 'Users',
          id: 'name',
        },
      ],
      isLoaded: false,
    }),
    computed: {
      ...mapState(useManagementCarriersStore, ['currentCarrier']),
      ...mapWritableState(useManagementCarriersStore, ['currentCarrierId', 'carrierUsers']),
    },
    /**
     * On creation, set the current carrier id, retrieve the carrier users, and sort the users by last name.
     */
    async created() {
      this.currentCarrierId = this.carrierId;

      try {
        const { users } = await ManagementCarrierService.getCarrierUsers(this.carrierId);

        this.carrierUsers.splice(0, this.carrierUsers.length, ...sortObjects(users, 'last_name'));
      } catch {
        this.$message({
          message: 'Could not retrieve carrier user list.',
        });
      } finally {
        this.isLoaded = true;
      }
    },
    /**
     * Reset the carrier users array on component destruction.
     */
    destroyed() {
      this.carrierUsers = [];
    },
  };
</script>

<style scoped>
  section {
    position: relative;
    margin-top: 20px;
  }
</style>
