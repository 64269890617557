<template>
  <ElDialog
    :visible.sync="visible"
    :show-close="false"
    :close-on-click-modal="false"
  >
    <template #title>
      <div class="custom-title">
        <div class="title-container">
          <span>{{ parameters.title }}</span>
          <TfBadge
            v-if="response"
            :value="responseCode"
            :status="responseCode === 200 ? 'success' : 'error'"
            size="small"
            class="custom-badge"
          />
        </div>
        <AppButton
          icon="fa-light fa-xmark"
          size="text"
          @click="handleClose"
        />
      </div>
    </template>
    <ElForm
      v-if="parameters.inputs"
      ref="form"
      :model="formData"
      :rules="rules"
    >
      <ElFormItem
        v-for="(input, index) in parameters.inputs"
        :key="input.parameterId + index"
        :prop="input.parameterId"
        class="form-item"
      >
        <div
          class="input-label"
          :class="{ 'with-desc': input.description }"
        >
          {{ input.label }}
          <AppIcon
            v-if="validations.hasOwnProperty(input.parameterId)"
            :icon="getRecordValStyle(input.parameterId).icon"
            :style="getRecordValStyle(input.parameterId).style"
            class="validation-badge"
            size="1x"
          />
          <p
            :style="{
              visibility: validations.hasOwnProperty(input.parameterId) ? 'visible' : 'hidden',
              color: getRecordValStyle(input.parameterId).style.color,
            }"
            class="validation-message"
          >
            {{ validations[input.parameterId]?.validated ? validations[input.parameterId].value : 'One or more records do not exist' }}
          </p>
        </div>
        <div v-if="input.description" class="input-description">
          {{ input.description }}
        </div>

        <ElInput
          v-model="formData[input.parameterId]"
          :type="input.type"
          :placeholder="input.placeholder"
          :rows="3"
          @blur="handleValidateRecord($event, input)"
        />
      </ElFormItem>
    </ElForm>
    <div v-if="response" class="response-block">
      <AppButton
        size="small"
        type="secondary"
        text="COPY"
        class="copy-button"
        @click="copyResult"
      />
      <pre class="response-code">
        <code>{{ response }}</code>
      </pre>
    </div>
    <div class="btn-group">
      <AppButton
        size="text"
        type="primary"
        :text="response ? 'Close' : 'Cancel'"
        @click="handleClose"
      />
      <AppButton
        type="primary"
        text="Submit"
        :is-disabled="isDisabled"
        @click="handleSubmit"
      />
    </div>
  </ElDialog>
</template>

<script>
  import { makeRequest } from '@/services/supportService.js';

  /**
   * Handles the display and submission of a request form within a modal dialog.
   *
   * @vuedoc
   * @exports RequestModal
   * @category Components
   */
  export default {
    props: {
      /**
       * Controls the visibility of the modal.
       *
       * @type {boolean}
       */
      value: {
        type: Boolean,
        required: true,
      },
      /**
       * Values that represent endpoint information for each request.
       *
       * @type {object}
       * @property {string} title - title of request
       * @property {string} endpoint - endpoint string
       * @property {string} method - HTTP method type
       * @property {object} inputs - endpoint attributes
       */
      parameters: {
        type: Object,
        required: true,
      },
    },
    data() {
      return {
        formData: {},
        rules: {},
        response: null,
        responseCode: null,
        validations: {},
      };
    },
    computed: {
      visible: {
        get() {
          return this.value;
        },
        set(value) {
          this.$emit('input', value);
        },
      },
      /**
       * Validate that all required fields have input and that there are no failed validations before allowing submission.
       *
       * @returns {boolean} - Returns true if all required fields are filled and no failed validations.
       */
      isDisabled() {
        // check for failed record validations
        const hasRecordValidationFails = Object.values(this.validations).some(
          (value) => value?.validated === false,
        );

        // return true if any inputs empty
        if (this.parameters.inputs) {
          const hasEmptyRequiredFields = this.parameters.inputs
            .filter((input) => input.required)
            .some((input) => !this.formData[input.parameterId] || this.formData[input.parameterId].trim() === '');

          if (hasEmptyRequiredFields) {
            return true;
          }

          let hasValidationErrors = false;

          // check if any element ui validations exist via callback
          if (this.$refs.form) {
            this.$refs.form.validate((valid) => {
              hasValidationErrors = !valid;
            });
          }

          return hasRecordValidationFails || hasValidationErrors;
        }

        return hasRecordValidationFails;
      },
    },
    watch: {
      /**
       * When the visibility changes and we get new formData, create new rules for new form data.
       *
       * @param {boolean} value - The visibility value
       */
      visible(value) {
        if (value === true) {
          this.createRules();
        } else {
          this.rules = {};
        }
      },
    },
    methods: {
      /**
       * Dynamically generate validation rules for current form data.
       */
      createRules() {
        this.parameters.inputs.forEach((input) => {
          this.rules[input.parameterId] = [];
          if (input.required) {
            this.rules[input.parameterId] = [
              {
                required: true,
                message: `${(input.label.replace('*', '')).trim()} is required`,
                trigger: 'blur',
              },
            ];
          }
          if (input.data_type === 'email') {
            this.rules[input.parameterId].push({
              type: 'email',
              message: 'Please enter a valid email address',
              trigger: 'blur',
            });
          }
          if (input.data_type === 'number') {
            this.rules[input.parameterId].push({
              validator: (rule, value, callback) => {
                const regex = input.array ? /^\d+(,\s*\d+)*$/ : /^\d+$/;

                if (!regex.test(value)) {
                  callback(new Error('Please enter a valid ID'));
                } else {
                  callback();
                }
              },
              trigger: 'blur',
            });
          }
        });
      },
      /**
       * Return the appropriate icon and it's color based on the validation result.
       *
       * @param {string} parameterId - ID of parameter getting validated.
       * @returns {object} - Style object containing icon and style properties.
       */
      getRecordValStyle(parameterId) {
        const isValid = this.validations[parameterId]?.validated;

        return {
          icon: `fa-regular fa-circle-${isValid ? 'check' : 'exclamation'}`,
          style: {
            color: `var(--tf-${isValid ? 'green' : 'orange'})`,
          },
        };
      },
      /**
       * Copy response to clipboard.
       *
       */
      copyResult() {
        navigator.clipboard.writeText(this.response);
      },
      /**
       * Close the request modal & clear it's fields.
       *
       */
      handleClose() {
        this.$refs.form.clearValidate();
        this.formData = {};
        this.response = null;
        this.rules = {};
        this.validations = {};
        this.$emit('input', false);
      },
      /**
       * Extract input data and convert to different data types if required
       *
       * @returns {object}
       */
      handleFormatInput() {
        const params = {};

        this.parameters.inputs.forEach((input) => {
          if (input.parameterId) {
            if (input.array && this.formData[input.parameterId]) {
              const formattedValue = this.formData[input.parameterId].split(',').map((item) => item.trim());

              params[input.parameterId] = formattedValue;
            } else {
              params[input.parameterId] = this.formData[input.parameterId];
            }
          }
        });

        return params;
      },
      /**
       * Check if validation errors exist on a given input.
       *
       * @param {string} value
       * @returns {boolean}
       */
      async checkInputError(value) {
        let hasError = false;

        if (value) {
          await this.$refs.form.validateField(value, (errorMessage) => {
            if (errorMessage) {
              hasError = true;
            }
          });
        }

        return hasError;
      },
      /**
       * Look up record for provided input. Prevent submission & add to errors if not valid.
       *
       * @param {Event} event
       * @param {object} input
       * @returns {object}
       */
      async handleValidateRecord(event, input) {
        if (await this.checkInputError(input.parameterId)) {
          this.$delete(this.validations, input.parameterId);

          return; // return early - don't validate if there's a validation error
        }

        const validation_endpoint = input.validation_string;
        let { value } = event.target;

        // split the value if there are multiple provided
        value = value.includes(',') ? value.split(',') : [value];

        if (value && validation_endpoint) {
          const responses = await Promise.all(
            value.map((val) => {
              const params = { [input.validation_parameter]: val.trim() };

              return makeRequest(validation_endpoint, params, 'GET');
            }),
          );
          // set value to true if all responses were successful
          const validated_value = responses.every((response) => response.status === 200);
          const responseValue = [];

          // if we are displaying a value from the record validation, add each one to an array
          if (validated_value && input.targeted_value) {
            responses.forEach((response) => {
              const firstKey = Object.keys(response.data)[0];
              const firstObject = response.data[firstKey];

              responseValue.push(firstObject[input.targeted_value]);
            });
          }

          this.$set(this.validations, input.parameterId, {
            validated: validated_value,
            value: responseValue.length ? responseValue.join(', ') : null,
          });
        } else if (!value) {
          this.$delete(this.validations, input.parameterId);
        }
      },
      /**
       * Make the request with params & display response data.
       *
       */
      async handleSubmit() {
        const { endpoint, method } = this.parameters;
        const params = this.handleFormatInput();

        try {
          const response = await makeRequest(endpoint, params, method);

          this.responseCode = response.status;
          if (response.status === 200) {
            this.response = JSON.stringify(response.data, null, 2);
            this.displayToast({
              message: 'Request success',
              type: 'success',
            });
          } else {
            this.response = response.data.message || 'An error occurred';
            this.displayToast({
              message: 'Request failed',
              type: 'error',
            });
          }
        } catch (error) {
          this.displayToast({
            message: 'Request failed',
            type: 'error',
          });
        }
      },
    },
  };
</script>

<style scoped lang="scss">
/* stylelint-disable declaration-no-important */
.custom-title {
  display: flex;
  align-items: center;
  justify-content: space-between;

  span {
    font-size: 16px;
    font-weight: bold;
    margin-right: 10px;
  }
}

.custom-badge {
  border: none !important;
  padding: 5px !important;
  font-size: 12px !important;
}

.form-item {
  margin-bottom: 36px;
}

.title-container {
  display: flex;
  align-items: center;
}

.input-label {
  display: flex;
  align-items: center;
  font-weight: bold;
  margin: 0;
  margin-bottom: 12px;

  &.with-desc {
    margin-bottom: 0;
  }

  .validation-badge {
    margin-left: 8px;
    margin-top: 2px;
  }

  .validation-message {
    margin: 0 8px;
    font-style: italic;
    font-weight: normal;
  }
}

::v-deep .el-dialog {
  padding: 24px;
  width: 50%;
  border-radius: 10px;
  margin-top: 10vh !important;
}

@media (max-width: 1100px) {
  ::v-deep .el-dialog {
    width: 70%;
  }
}

::v-deep .el-input__inner {
  border-radius: 4px;
}

::v-deep .el-textarea__inner {
  border-radius: 4px;
}

.input-description {
  margin-bottom: 12px;
  margin-top: 6px;
  font-size: 12px;
  color: var(--tf-gray);
}

.response-block {
  position: relative;
  padding: 20px;
  border-radius: 4px;
  background-color: var(--tf-gray-light-medium);
  max-height: 250px;
  overflow: auto;
  margin-bottom: 30px;
}

.response-code {
  margin: 0;
  white-space: pre-wrap;
  text-align: left;
  display: flex;
  align-items: flex-start;
  padding-bottom: 8px;
}

.copy-button {
  position: absolute;
  right: 14px;
  top: 14px;

  &:active {
    transform: scale(.95);
  }
}
/* stylelint-enable declaration-no-important */
</style>
