<template>
  <div
    :class="{ offset }"
    class="toolbar right toolbar-search"
  >
    <div
      class="toolbar-sub toolbar-sub-search"
      data-test="search toolbar"
    >
      <ElInput
        ref="searchTerm"
        v-model="localSearchTerm"
        size="medium"
        type="text"
        class="search-term"
        data-test="text search"
        @keyup.enter.native="submitSearch"
      />
      <span
        v-if="searchResults.length > 0 || searchError"
        class="search-pagination"
        data-test="search pagination"
      >{{ searchError ? 0 : searchResultIndex + 1 }} / {{ searchError ? 0 : searchResults.length }}</span>
      <AppButton
        v-if="searchResults.length > 0"
        type="secondary"
        icon="fa-solid fa-arrow-up"
        size="icon"
        data-test="search up"
        text="Previous result"
        @click="setSearchResultIndex(searchResultIndex - 1)"
      />
      <AppButton
        v-if="searchResults.length > 0"
        type="secondary"
        icon="fa-solid fa-arrow-down"
        size="icon"
        data-test="search down"
        text="Next result"
        @click="setSearchResultIndex(searchResultIndex + 1)"
      />
      <AppButton
        data-test="close button"
        type="secondary"
        icon="fa-solid fa-times"
        size="icon"
        text="Close search"
        @click="closeSearchToolbar"
      />
    </div>
  </div>
</template>

<script>
  import { mapState, mapActions } from 'pinia';
  import { usePdfSearchStore } from '@/stores/pdfSearch.js';
  import { usePdfViewerStore } from '@/stores/pdfViewer.js';
  import { useProjectStore } from '@/stores/project.js';
  /**
   * Search controls for PDF Viewer
   *
   * @vuedoc
   * @exports SearchToolbar
   * @category Components
   */
  export default {
    name: 'SearchToolbar',
    props: {
      offset: {
        type: Boolean,
        default: false,
      },
    },
    data: () => ({
      localSearchTerm: '',
    }),
    computed: {
      ...mapState(usePdfSearchStore, [
        'searchError',
        'searchResults',
        'searchResultIndex',
        'searchTerm',
      ]),
      ...mapState(useProjectStore, ['currentProject']),
    },
    created() {
      this.localSearchTerm = this.searchTerm;
      this.$nextTick(() => {
        this.$refs.searchTerm.focus();
      });
    },
    methods: {
      ...mapActions(usePdfSearchStore, [
        'initializeSearch',
        'setSearchResultIndex',
      ]),
      ...mapActions(usePdfViewerStore, ['togglePdfViewerToolbar']),
      /**
       * Run user's search in the store
       */
      submitSearch() {
        if (this.localSearchTerm !== this.searchTerm) {
          // new search
          this.initializeSearch(this.localSearchTerm.trim());
        } else {
          // go to next search result
          this.setSearchResultIndex(this.searchResultIndex + 1);
        }
      },
      /**
       * Close search toolbar
       */
      closeSearchToolbar() {
        this.initializeSearch();
        this.togglePdfViewerToolbar('search');
      },
    },
  };
</script>

<style lang="scss" scoped>
  .search-pagination {
    margin: 0 15px 0 0;
    white-space: nowrap;
    font-size: 14px;
    color: var(--tf-blue);
  }

  .search-term {
    margin-right: 15px;
  }
</style>
